import dayjs from 'dayjs';

export function getTimeRange(
  duration: 'day' | 'week' | 'month' | 'halfYear',
  showTime?: boolean, // 新增可选参数 showTime
): {
  start: string;
  end: string;
} {
  const now = dayjs();

  let start: dayjs.Dayjs;
  let end: dayjs.Dayjs;

  if (duration === 'day') {
    start = now.subtract(1, 'day');
    end = now.endOf('day');
  } else if (duration === 'week') {
    start = now.subtract(1, 'week');
    end = now.endOf('day');
  } else if (duration === 'month') {
    start = now.subtract(1, 'month');
    end = now.endOf('day');
  } else if (duration === 'halfYear') {
    start = now.subtract(6, 'month');
    end = now.endOf('day');
  } else {
    throw new Error('Invalid duration');
  }

  // 根据 showTime 参数决定时间格式
  const formatStr = showTime ? 'YYYY-MM-DD HH:mm:ss' : 'YYYY-MM-DD';


  return {
    start: start.format(formatStr),
    end: end.format(formatStr),
  };
}
