import { BaseFlex } from '@/components/base/baseFlex';
import { getTimeRange } from '@/components/features/featuresDateTime/utils';
import XDateTimePicker, {
  XDateTimePickerProps,
} from '@/components/form/formDateTimePicker';
import { Button } from '@nextui-org/react';
import { useModel } from '@umijs/max';
import dayjs from 'dayjs';
import { useTheme } from 'next-themes';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type FeaturesDateTimeProps = XDateTimePickerProps;

// 时间选择器 ，高度业务耦合
export function FeaturesDateTime(props: FeaturesDateTimeProps) {
  const { setLockScroll } = useModel('system');
  useEffect(() => {
    if (props.open) {
      setLockScroll(true);
    } else {
      setLockScroll(false);
    }
  }, [props.open]);

  const { value, onConfirm, ...reset } = props;

  const { t } = useTranslation();

  // 时间选择器
  const [dateTimeValue, setDateTimeValue] = useState<any>(value);

  // 顶部时间选择按钮默认值
  const [selectedValue, setSelectedValue] = useState<number>(2);
  // 订单排序选择按钮
  const [orderSort, setOrderSort] = useState<number>(1);
  // 时间选择
  const [time, setTime] = useState<number>(0);

  // 展示时间
  const [showTime, setShowTime] = useState({
    start: '',
    end: '',
  });

  // 顶部时间选择按钮
  const selectedButtonList = [
    {
      label: t('1天'),
      value: 1,
    },
    {
      label: t('1周'),
      value: 2,
    },
    {
      label: t('1个月'),
      value: 3,
    },
    {
      label: t('6个月'),
      value: 4,
    },
  ];

  // 订单排序选择按钮
  const selectedButton = [
    {
      label: t('创建时间'),
      value: 1,
    },
    {
      label: t('更新时间'),
      value: 2,
    },
  ];

  useEffect(() => {
    setDateTimeValue(value);
  }, [value]);

  useEffect(() => {
    switch (selectedValue) {
      case 1:
        setShowTime(getTimeRange('day', props.showTime));
        setTime(0);
        break;
      case 2:
        setShowTime(getTimeRange('week', props.showTime));
        setTime(0);
        break;
      case 3:
        setShowTime(getTimeRange('month', props.showTime));
        setTime(0);
        break;
      case 4:
        setShowTime(getTimeRange('halfYear', props.showTime));
        setTime(0);
        break;
    }
  }, [selectedValue]);

  useEffect(() => {
    switch (time) {
      case 1:
        setDateTimeValue(showTime.start);
        console.log(dayjs(showTime.start).format('YYYY-MM-DD'), '===');

        break;
      case 2:
        setDateTimeValue(showTime.end);
        break;
    }
  }, [time]);

  // 使用useTheme钩子获取当前主题和设置主题的函数
  const { theme } = useTheme();

  return (
    <XDateTimePicker
      value={dateTimeValue}
      itemHeight={36}
      theme={theme as any}
      onChange={(value) => {
        if (time === 1) {
          setShowTime({
            ...showTime,
            start: dayjs(value).format('YYYY-MM-DD'),
          });
        } else {
          setShowTime({
            ...showTime,
            end: dayjs(value).format('YYYY-MM-DD'),
          });
        }
      }}
      unSelectedColor="text-auxiliaryTextColor"
      selectedColor="text-titleColor"
      title={
        <BaseFlex
          align="center"
          flex={1}
          justify="center"
          vertical
          className="p-3"
        >
          <BaseFlex align="center" justify="center" flex={1}>
            <span className="text-[16px] text-titleColor font-bold">
              {t('筛选')}
            </span>
          </BaseFlex>
          <BaseFlex
            align="center"
            justify="center"
            vertical
            flex={1}
            className="py-3"
          >
            <BaseFlex align="start" justify="start" flex={1}>
              <span className="text-auxiliaryTextColor">{t('时间')}</span>
            </BaseFlex>
            <BaseFlex
              align="center"
              justify="between"
              flex={1}
              className="py-2"
            >
              {selectedButtonList.map((item) => (
                <Button
                  key={item.value}
                  size="sm"
                  radius="sm"
                  variant="bordered"
                  className={
                    selectedValue === item.value
                      ? 'border-2 border-primary'
                      : 'warning'
                  }
                  color={'default'}
                  onClick={() => {
                    setSelectedValue(item.value);
                  }}
                >
                  {item.label}
                </Button>
              ))}
            </BaseFlex>
          </BaseFlex>
          <BaseFlex align="center" justify="between" flex={1}>
            <Button
              radius="sm"
              variant="bordered"
              className={
                time === 1
                  ? 'text-backgroundTextColor bg-primary w-full text-xs'
                  : 'warning w-full text-xs'
              }
              color={'default'}
              onClick={() => setTime(1)}
            >
              {showTime.start}
            </Button>
            <span className="mx-6 text-auxiliaryTextColor">{t('至')}</span>
            <Button
              radius="sm"
              variant="bordered"
              className={
                time === 2
                  ? 'text-backgroundTextColor bg-primary w-full text-xs'
                  : 'warning w-full text-xs'
              }
              color={'default'}
              onClick={() => setTime(2)}
            >
              {showTime.end}
            </Button>
          </BaseFlex>
        </BaseFlex>
      }
      footerSlots={[
        <BaseFlex align="center" justify="start" flex={1} vertical key="bottom">
          <BaseFlex align="center" justify="start" flex={1}>
            <span className="text-auxiliaryTextColor">
              *{t('仅显示6个月内的历史记录')}
            </span>
          </BaseFlex>
          <BaseFlex align="center" justify="start" flex={1} className="py-3">
            <span className="text-auxiliaryTextColor">{t('订单排序')}</span>
          </BaseFlex>
          <BaseFlex
            align="center"
            justify="between"
            flex={1}
            className="w-full space-x-2"
          >
            {selectedButton.map((item) => (
              <Button
                key={item.value}
                size="md"
                radius="sm"
                variant="bordered"
                style={{ width: '100%' }}
                className={
                  orderSort === item.value
                    ? 'bg-primary border-0 text-backgroundAuxiliaryColor'
                    : 'warning'
                }
                color={'default'}
                onClick={() => {
                  setOrderSort(item.value);
                }}
              >
                {item.label}
              </Button>
            ))}
          </BaseFlex>
          <BaseFlex
            align="center"
            justify="between"
            flex={1}
            className="py-3 mt-4 space-x-2"
          >
            <Button
              size="md"
              radius="sm"
              variant="bordered"
              style={{ width: '100%' }}
              onClick={() => {
                setTime(0);
                setSelectedValue(2);
                setDateTimeValue(dayjs());
              }}
            >
              {t('重置')}
            </Button>
            <Button
              size="md"
              radius="sm"
              style={{ width: '100%' }}
              className="bg-primary text-primaryButtonTextColor"
              color="warning"
              onClick={() => onConfirm && onConfirm(showTime as any)}
            >
              {t('确认')}
            </Button>
          </BaseFlex>
        </BaseFlex>,
      ]}
      {...reset}
    />
  );
}
