import React, {CSSProperties, ReactNode} from 'react';

/**
 * XSpace组件用于在React中添加间隔地展示子元素。
 * 它可以根据指定的方向（水平或垂直）和间隔大小，在子元素之间添加空白。
 *
 * @param props 包含组件的配置项，其中：
 *    - children: ReactNode，组件的子元素。
 *    - size: number | string | Array<number | string>，间隔的大小，默认为8。可以是单个值，或针对不同方向设置不同大小的数组。
 *    - direction: 'horizontal' | 'vertical'，间隔的方向，默认为'horizontal'。
 * @returns 返回一个包含经过间隔处理的子元素的div元素。
 */
export function XSpace(props: XSpaceProps) {
  const { children, size = 8, direction = 'horizontal' } = props;
  // 将 size 属性转换为适用于样式的边距值
  const remSize = Array.isArray(size)
    ? size
        .map((s: number | string) => `${parseFloat(s.toString()) / 16}rem`)
        .join(' ')
    : `${parseFloat(size.toString()) / 16}rem`;

  // 将子元素转换为数组，以便于遍历和处理。
  const childrenArray = React.Children.toArray(children);

  // 渲染子元素，为每个子元素添加间隔。
  const renderChildren = () => {
    return childrenArray.map((child, index) => {
      const isFirst = index === 0; // 判断是否为第一个元素。
      const isLast = index === childrenArray.length - 1; // 判断是否为最后一个元素。

      return (
        // 为每个子元素的外层容器添加间隔样式后渲染。
        <div key={index} style={{ margin: remSize }} className="w-full">
          {child}
        </div>
      );
    });
  };

  // 根据方向设置容器的flex样式。
  const containerStyle: CSSProperties =
    direction === 'horizontal'
      ? { display: 'flex', flexDirection: 'row' }
      : { display: 'flex', flexDirection: 'column' };

  // 渲染包含所有经过处理的子元素的容器。
  return <div style={containerStyle}>{renderChildren()}</div>;
}

/**
 * XSpaceProps接口定义了XSpace组件的props结构。
 */
export interface XSpaceProps {
  children: ReactNode; // 组件的子元素。
  size?: number | string | Array<number | string>; // 间隔的大小，可选。
  direction?: 'horizontal' | 'vertical'; // 间隔的方向，可选，默认为'horizontal'。
}
