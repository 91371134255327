import BaseModal from '@/components/base/baseModal';
import { XSpace } from '@/components/base/baseSpace';
import { DateTimeUtils } from '@/components/form/formDateTimePicker/utils';
import dayjs, { Dayjs } from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PickerRootProps, PickerValue, XPicker } from '../formPicker';

function renderOptions(
  options: number[] | string[],
  selectedColor: string,
  unSelectedColor: string,
) {
  return options.map((option) => (
    <XPicker.Item key={option} value={option}>
      {({ selected }: any) => (
        <div
          className={selected ? `font-bold ${selectedColor}` : unSelectedColor}
        >
          {option}
        </div>
      )}
    </XPicker.Item>
  ));
}

export default function XDateTimePicker(props: XDateTimePickerProps) {
  const { t } = useTranslation();

  const {
    onCancel,
    onChange,
    onConfirm,
    open,
    title,
    slots = [],
    value,
    initValue,
    showTime = false,
    confirmButtonText = t('确认'),
    cancelButtonText = t('取消'),
    theme = 'light',
    selectedColor = 'text-blue',
    unSelectedColor = 'text-gray-600',
    footerSlots = [],
    itemHeight = 46,
    ...reset
  } = props;

  const [show, setShow] = useState(open);
  const [pickerValue, setPickerValue] = useState<PickerValue>(() => {
    if (initValue instanceof dayjs) {
      return DateTimeUtils.getDateParts(initValue);
    }
    if (typeof initValue === 'string') {
      return DateTimeUtils.getDateParts(initValue);
    }
    if (value instanceof dayjs) {
      return DateTimeUtils.getDateParts(value);
    }
    if (typeof value === 'string') {
      return DateTimeUtils.getDateParts(value);
    }
    if (!initValue && !value) {
      return DateTimeUtils.getDateParts(dayjs().format('YYYY-MM-DD HH:mm:ss'));
    }
    return {
      year: '',
      month: '',
      days: '',
      hour: '',
      minute: '',
      second: '',
    };
  });

  // 年月 时分秒数据
  const dateList = DateTimeUtils.getDateValue(35, 30);

  // 日
  const daysList = useMemo(() => {
    if (pickerValue.year && pickerValue.month) {
      return DateTimeUtils.getDaysInMonth(
        Number(pickerValue.year),
        Number(pickerValue.month),
      );
    }
    return [];
  }, [pickerValue.year, pickerValue.month]);

  useEffect(() => {
    if (open) {
      if (!value && !initValue) {
        const defaultSelected = DateTimeUtils.getDateParts(dayjs());
        setPickerValue({ ...defaultSelected });
      }
      setShow(true);
    } else {
      setShow(false);
    }
  }, [open]);

  useEffect(() => {
    let dateValue: Dayjs | string | undefined;

    if (initValue instanceof dayjs) {
      dateValue = initValue;
    } else if (typeof initValue === 'string') {
      dateValue = dayjs(initValue);
    } else if (value instanceof dayjs) {
      dateValue = value;
    } else if (typeof value === 'string') {
      dateValue = dayjs(value);
    }

    if (dateValue) {
      const updatedPickerValue = DateTimeUtils.getDateParts(dateValue);
      setPickerValue(updatedPickerValue);
    } else {
      setPickerValue(DateTimeUtils.getDateParts(dayjs()));
    }
  }, [initValue, value]);

  // 主题
  const themeChange = theme === 'dark' ? 'rgb(30 30 30)' : '#eceaea';

  return (
    <BaseModal
      style={{ backgroundColor: themeChange }}
      isOpen={show}
      position="bottom"
      onClose={reset.onClose}
      title={''}
      {...reset}
    >
      <div>
        {typeof title === 'string' ? (
          <div className="flex flex-1 flex-col px-3">
            <div className="flex flex-1 cursor-pointer items-center  justify-between">
              <div
                onClick={() => {
                  onCancel && onCancel(DateTimeUtils.assembleDate(pickerValue));
                }}
                className="text-foreground"
              >
                {cancelButtonText}
              </div>
              <div className="text-titleColor">{title}</div>
              <div
                className="cursor-pointer  rounded-md text-blue hover:bg-gray-300 focus:outline-none"
                onClick={() => {
                  onConfirm &&
                    onConfirm(DateTimeUtils.assembleDate(pickerValue));
                }}
              >
                {confirmButtonText}
              </div>
            </div>
            {slots.length !== 0 && (
              <div className="flex flex-1 flex-wrap items-center justify-start py-1">
                {slots.map((item, index) => item)}
              </div>
            )}
          </div>
        ) : (
          title
        )}
      </div>
      <XPicker
        className="px-4"
        itemHeight={itemHeight}
        value={pickerValue}
        height={180}
        lineColor={theme === 'dark' ? '#343843' : '#000'}
        onChange={(value, key) => {
          setPickerValue({ ...pickerValue, ...value });
          onChange && onChange(DateTimeUtils.assembleDate(value), key);
        }}
      >
        <XPicker.Column name="year">
          {renderOptions(dateList.yearList, selectedColor, unSelectedColor)}
        </XPicker.Column>
        <XPicker.Column name="month">
          {renderOptions(dateList.monthList, selectedColor, unSelectedColor)}
        </XPicker.Column>
        <XPicker.Column name="days">
          {renderOptions(daysList, selectedColor, unSelectedColor)}
        </XPicker.Column>
        {showTime && (
          <>
            <XPicker.Column name="hour">
              {renderOptions(dateList.hourList, selectedColor, unSelectedColor)}
            </XPicker.Column>
            <XPicker.Column name="minute">
              {renderOptions(
                dateList.minuteList,
                selectedColor,
                unSelectedColor,
              )}
            </XPicker.Column>
            <XPicker.Column name="second">
              {renderOptions(
                dateList.secondList,
                selectedColor,
                unSelectedColor,
              )}
            </XPicker.Column>
          </>
        )}
      </XPicker>
      {footerSlots.length !== 0 &&
        footerSlots.map((item, index) => (
          <XSpace size={10} key={index}>
            {item}
          </XSpace>
        ))}
    </BaseModal>
  );
}

export interface XDateTimePickerProps
  extends Pick<PickerRootProps<any>, 'itemHeight'> {
  onCancel?: (value: Dayjs | null) => void;
  onClose?: (value: Dayjs | null) => void;
  onChange?: (pickerValue: Dayjs | null, key: string) => void;
  onConfirm?: (value: Dayjs | null) => void;
  value?: Dayjs | string;
  initValue?: Dayjs | string;
  slots?: React.ReactNode[];
  footerSlots?: React.ReactNode[];
  title?: React.ReactNode | string;
  showTime?: boolean;
  open: boolean;
  confirmButtonText?: string;
  cancelButtonText?: string;
  theme?: 'light' | 'dark';
  selectedColor?: string;
  unSelectedColor?: string;
}
