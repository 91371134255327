import dayjs, { Dayjs } from 'dayjs';

export interface PickerValue {
  [key: string]: string;
}

// 返回数据类型
interface DateTimeDataProps {
  yearList: string[];
  monthList: string[];
  hourList: string[];
  minuteList: string[];
  secondList: string[];
}

export class DateTimeUtils {
  // 获取年
  static getYearRange(startYearNumber: number, endYearNumber: number): string[] {
    // 获取当前年份
    const currentYear = dayjs().year();
    // 设置开始年份为当前年份的前10年
    const startYear = currentYear - startYearNumber;
    // 设置结束年份为当前年份的后10年
    const endYear = currentYear + endYearNumber;

    const yearRange = [];

    // eslint-disable-next-line no-plusplus
    for (let year = startYear; year <= endYear; year++) {
      yearRange.push(String(year));
    }

    return yearRange;
  }

  // 获取月
  static getMonthRange(): string[] {
    return Array.from({ length: 12 }, (_, index) => (index + 1).toString().padStart(2, '0'));
  }

  // 获取日
  static getDaysInMonth(year: number, month: number): string[] {
    const startDate = dayjs(`${year}-${month}-01`, 'YYYY-M-D');
    const endDate = startDate.endOf('month');

    const days = [];
    let currentDate = startDate;

    while (currentDate.isSame(endDate) || currentDate.isBefore(endDate)) {
      const formattedDate = currentDate.date().toString().padStart(2, '0');
      days.push(formattedDate);
      currentDate = currentDate.add(1, 'day');
    }

    return days;
  }

  // 获取时
  static getHourRange(): string[] {
    return Array.from({ length: 24 }, (_, index) => (index + 1).toString().padStart(2, '0'));
  }

  // 获取分秒
  static getTimeRange(): string[] {
    return Array.from({ length: 60 }, (_, index) => (index + 1).toString().padStart(2, '0'));
  }

  // 时间转换
  static assembleDate(pickerValue: PickerValue): Dayjs | null {
    const { year, month, days, hour, minute, second } = pickerValue;

    if (!year || !month || !days) {
      return null;
    }

    const trimLeadingZero = (value: string) => {
      if (value.length === 2 && value.startsWith('0')) {
        return value.substring(1);
      }
      return value;
    };

    const hourValue = hour ? trimLeadingZero(hour) : '1';
    const minuteValue = minute ? trimLeadingZero(minute) : '1';
    const secondValue = second ? trimLeadingZero(second) : '1';

    const date = dayjs()
      .set('year', parseInt(year, 10))
      .set('month', parseInt(month, 10) - 1)
      .set('date', parseInt(days, 10))
      .set('hour', parseInt(hourValue, 10))
      .set('minute', parseInt(minuteValue, 10))
      .set('second', parseInt(secondValue, 10));

    return date;
  }

  // dayjs或者string 转换
  static getDateParts(dateValue: Dayjs | string): PickerValue {
    if (!dateValue) {
      return {
        year: '',
        month: '',
        days: '',
        hour: '',
        minute: '',
        second: '',
      };
    }

    const dateObject = dayjs(dateValue);

    if (!dateObject.isValid()) {
      return {
        year: '',
        month: '',
        days: '',
        hour: '',
        minute: '',
        second: '',
      };
    }

    const year = dateObject.year().toString();
    const month = (dateObject.month() + 1).toString().padStart(2, '0');
    const days = dateObject.date().toString().padStart(2, '0');
    const hour = dateObject.hour().toString().padStart(2, '0');
    const minute = dateObject.minute().toString().padStart(2, '0');
    const second = dateObject.second().toString().padStart(2, '0');

    return {
      year,
      month,
      days,
      hour,
      minute,
      second,
    };
  }

  // 数据
  static getDateValue(startYearNumber: number, endYearNumber: number): DateTimeDataProps {
    // 年
    const yearList = this.getYearRange(startYearNumber, endYearNumber);
    // 月
    const monthList = this.getMonthRange();

    // 分秒
    const timeList = this.getTimeRange();
    // 时
    const hoursList = this.getHourRange();

    return {
      yearList,
      monthList,
      hourList: hoursList,
      minuteList: timeList,
      secondList: timeList,
    };
  }
}
